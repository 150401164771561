
* {
  font-family: "Roboto", sans-serif !important;
  font-size: 1 rems !important;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.HeaderContainer{
  padding:0 30px;
  background: #F9F7F7;
  border-bottom: 1px solid #dde4f3;
}
.bodyContainer{
  margin-top: 64px;
  margin-bottom: 20px;
  padding:0 30px;
  min-height: 60vh;
}

.primarylinkcolor {
  color: #4267B2
}

.secondarylinkcolor{
  color: #28A0F7;
}

.overall{
  font-size: 1.5 rems;
  font-weight: bold;
  white-space: nowrap;    /* Anitha 11/07/24 */
}


.bodyHeader{
  font-size: calc(1.155rem + 1.0vw) !important;
  padding: 7px 0 ;
  /* border-bottom: 1px solid #ccc; */
  color: 848181; 
  display: block;
}

.footerContainer{
  /* margin-top: 103px;   */
  /* margin-top: 119px;     */
     /* Anitha 11/07/24 */
  /* padding:15px 30px;  */
  /* padding:15px;    */
   /* Anitha 11/07/24 */
  /* background: #C9DFF1; 
  display: block; */
  /*ANITHA 11112024*/
  /* margin-top: 19.7vh;  */
  margin-top: 16vh;
  background: #C9DFF1; 
  display: block;
}

.staffIcon{
  width: 32px;
  height:32px;
}

.padright{
  padding-right: 15px;
}

.star {
  font-size: 34px;
}

.empty {
  color: lightgray; 
}

.filled {
  color:gold;
}

.cmtbtn{
 margin-right: 30px;
}

.cmtinput{
  margin-right: 35px;

}

 /* .form-range::-webkit-slider-runnable-track {
  background: #007bff; 
  height: 8px; 
  border-radius: 5px; 
} */

.form-range .filled{
  color:#28A0F7
}

/* .inputlength{
  width: 345px;
} */

.bordersubmit{
  border: 2px solid rgb(196, 195, 195);
}

.successsubmit{
  color:rgb(16, 156, 16);
}

.message{
  /* margin:  0px -15px;
  padding-top: 20px; */
  margin:   -35px 0px; 
  padding-top: 10px;  
}

.spannavy{
  color:#4267B2;
  text-decoration: none;
}

.form-check-label{
  font-size: 15px;
}

.bgcard{
  background-color:rgb(196, 195, 195,0.38);
  color: black;
  font-weight: bold;
}

.servicehead{
  padding: 5px;
  color: rgb(116, 114, 114)
}

.form-check-input{
  background-color: rgb(196, 195, 195,0.38);
}


#inputno{
  margin-left: 155px !important;
}

#inputno1{
  /* margin-left: 60px !important; */
  margin-left: 119px !important; 
} 


/* .stepper-container {
  display: flex;
  align-items: center;
  
} */

.stepper-content {
  text-align: center; /* Center the text inside */
}

.bi {
  cursor: pointer; /* Change cursor to pointer for clickable items */
  opacity: 0.6; /* Optional: give a disabled effect */
  margin-right: 60px;
}

.bi[disabled] {
  cursor: not-allowed; /* Change cursor for disabled items */
  opacity: 0.3; /* Optional: visually indicate disabled state */
}

.arrow-large{
  font-size: 4rem;
}

.arrow-filled {
  display: inline-block;
  width: 1.5em;  /* Adjust width */
  height: 1.5em; /* Adjust height */
  background-color: black; /* Change color as needed */
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
}

.smalltext{
  font-weight: bold;
}

.blue{
  color:#4267B2;;
}

.servicehead hr {
  border: none; 
  border-top: 2px solid #000; 
  width: 100%; 
}

.progress-bar{
  
  top:20%;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  
}
.progress{
  height: 100%;
  /* background-color: #4267B2; */
  transition: 0.2s ease;

} 

.star-row {
  display: inline-flex;

   /* Adjust spacing between stars */
}
/* 
 .progress-bar {
  position: absolute; 
  /* margin-left: 4%; */
  /* top: 24%;
  width: 100%;
  height: 2px;
  background-color: rgb(196, 195, 195);
  margin-top: 20px;
  z-index: -1;
}

.progress {
  height: 100%;
  background-color: rgb(196, 195, 195);
  transition: width 0.2s ease; 
} */ 

/* 
.steps-container {
  position: relative;
}

.progress-line {
  position: absolute;
  height: 2px;
  background: gray;
  width: 0;
  top: 50%;
  left: 0;
  z-index: -1;
  transition: all 0.25s ease;
}

.steps-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.steps {
  
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  cursor: pointer;
}

.steps.active {
  background-color: #4267B2;
} */

.stepper .steps-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}

.progress{
  position: absolute;
  height: 100%;
  background-color: rgb(196, 195, 195);
  transition: width 0.2s ease; 
  /* top:-10%; */
}

.progress-line {
  /* position: absolute; */
  height: 2px;
  background: gray;
  width: 0;
  /* top: 50%; */
  left: 0;
  z-index: -1;
  transition: all 0.25s ease;
}

.steps {
  width: 30px; 
  height: 30px; 
  background: #696a6b; 
  color: white; 
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s;
}

.steps.active {
  background: #0056b3; 
}

.steps:hover {
  background: #0056b3; 
}

.arrow {
  font-size: 1rem;
  color: #909396;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.arrow.active {
  color: #0056b3; 
}
/* .arrowstep2{
  margin-left: 100px !important;
} */

.before-step-2 {
  left: calc(33% - 0.75rem); /* Adjust this to center on the line before step 2 */
}

.after-step-2 {
  left: calc(66% - 0.75rem);
}


/* .margin-left-2 {
  margin-left: 270px; 
}

.margin-left-3 {
  margin-left: 270px; 
} */

/* Responsive styles */
/* @media (max-width: 768px) {
  .margin-left-2,
  .margin-left-3 {
    margin-left: 20%;
  }
  
}

/* .arrow-icon {
  position: absolute;
  top: -16px; 
  right: 0; 
  transform: translateX(-50%);
  font-size: 24px; 
  color: #000; 
  z-index: 1;
}  */


.stars{

font-size: 11px;
}

.staffIcon {
  width: 30px;   
  height: 30px;
  object-fit: cover;
}
/* 
.border-end {
  border-right: 1px solid #ccc; 
  padding-right: 10px;
} */


.nurseratingcard{
    background-color: #4267B2;
}

table {
  border: 1px solid rgb(151, 150, 150)!important; 
  border-collapse: collapse; 
}


th{
  border: 1px solid rgb(151, 150, 150) !important;
}
td {
  border: none;
}


.stepbtn{
  /* width: 300px; Anitha 11/07/2024 */ 
  width: 100px !important; 
}


.nurseratingcard{
    background-color: #4267B2;
}

table {
  border: 1px solid rgb(151, 150, 150)!important; 
  border-collapse: collapse; 
}


th{
  border: 1px solid rgb(151, 150, 150) !important;
}
td {
  border: none;
}

/* Anitha 11/07/2024 */
/* .stepbtn{
  width: 300px;
} */

.btnsubmit{
  background-color: #4267B2 !important;
}
.btnsubmit:hover{
  background-color: #4267B2 !important;
}

.btnSpace{ 
  padding-top: 2px;
}


  .table-responsive {
    overflow-x: auto;
  }
  .table {
    min-width: 700px; /* Adjust as necessary */
  }
  
  


  
.nurseratingcard{
  background-color: #4267B2 !important;
  font-size: 0.80rem !important;

}

table {
border: 1px solid rgb(151, 150, 150)!important; 
border-collapse: collapse; 
font-size: 0.80rem !important;

}


th{
border: 1px solid rgb(151, 150, 150) !important;
}
td {
border: none !important;
}

/* Anitha 11/07/2024 */
/* .stepbtn{
width: 300px;
} */

.stars{

  font-size: 11px;
  }
  
  .staffIcon {
    width: 30px;   
    height: 30px;
    object-fit: cover;
  }

  .nurserating{
    font-size: 0.80rem !important;
}

.staffIcons {
  width: 30px;   
  height: 30px;
  object-fit: cover;
}

.logoutbtn{
  color:#4267B2;
  font-weight: bold;
}

.upload{
  font-size: 0.80rem !important;
  font-weight: bold !important;
  color:#4267B2 !important;
}
.staff{
  font-weight: bold;
  font-size: 0.80rem !important;

}


.icon-bold {
  font-weight: bold !important;
  font-size: 2; 
}
.toggle{
  color: #4267B2 !important;
  width: 40px;
  height: 20px;
  font-size: 10px !important;
  font-weight: bold !important;
}

/* Mobile styles */
@media (max-width: 768px) {
  .commentbox {
    width: 100%;
  }
  .commentbox1 {
    /* padding-left: 42px; */
    padding-left: 50px; 
  }

}

/* Desktop styles */
@media (min-width: 769px) {
  .commentbox {
    width: 82%;
  }
}
/*Anitha 11/11/2024*/
.btnbottom{
  margin-bottom: -9%;
}



/* Default layout for larger screens */
.dflex {
  flex-wrap: wrap;  /* Allow items to wrap if needed */
  align-items: center;  /* Keep items aligned in the same row */
}

.starwrap {
  justify-content: flex-end !important;
}

@media (max-width: 768px) {
  /* Adjustments for smaller screens */
  .dflex {
    flex-direction: row; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    margin-right: 1rem; /* Removeany forced right margin */
  }

  .starwrap {
    display: block;  
    /* padding-left: 22.5% !important; */
    padding-left: 75px;
    margin-top: 5px; 
  }
  .btnbottom{ 
    margin-bottom: -35% !important; 

  } 
}

.ratingcount{
  padding-left: 80px !important;
}
